<template>
  <v-card>
    <v-card-title>
      <v-icon class="mr-1">
        mdi-history
      </v-icon>
      포인트 정보
    </v-card-title>
    <v-card-text>
      <v-row v-if="summary">
        <v-col cols="4" class="text-center pb-0 text-subtitle-1">
          구매
        </v-col>
        <v-col cols="4" class="text-center pb-0 text-subtitle-1">
          배송/구매확정
        </v-col>
        <v-col cols="4" class="text-center pb-0 text-subtitle-1">
          리뷰
        </v-col>
      </v-row>
      <v-row v-if="summary" class="mb-5">
        <v-col cols="4" class="text-center pt-0">
          {{ summary.buy.toLocaleString() }}
        </v-col>
        <v-col cols="4" class="text-center pt-0">
          {{ summary.delivery.toLocaleString() }}
        </v-col>
        <v-col cols="4" class="text-center pt-0">
          {{ summary.review.toLocaleString() }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="text-center pb-0 text-subtitle-1">
          활동
        </v-col>
        <v-col cols="4" class="text-center pb-0 text-subtitle-1">
          적립
        </v-col>
        <v-col cols="4" class="text-center pb-0 text-subtitle-1">
          추천
        </v-col>
      </v-row>
      <v-row class="mb-5">
        <v-col cols="4" class="text-center pt-0">
          {{ user.activityPoints ? user.activityPoints.toLocaleString() : 0 }}
        </v-col>
        <v-col cols="4" class="text-center pt-0">
          {{ user.feePoints ? user.feePoints.toLocaleString() : 0 }}
        </v-col>
        <v-col cols="4" class="text-center pt-0">
          {{ user.recommendPoints ? user.recommendPoints.toLocaleString() : 0 }}
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col cols="12" md="3">
          <v-select
            v-model="pointType"
            dense
            hide-details
            :items="pointTypes"
          />
        </v-col>
        <v-col cols="6" md="3">
          <v-menu>
            <template #activator="{ attrs, on }">
              <v-text-field
                v-model="startDate"
                label="Start"
                dense
                hide-details
                prepend-inner-icon="mdi-calendar-check"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="startDate"
              locale="ko"
              type="date"
              @change="saveStartDate"
            />
          </v-menu>
        </v-col>
        <v-col cols="6" md="3">
          <v-menu>
            <template #activator="{ attrs, on }">
              <v-text-field
                v-model="endDate"
                label="End"
                dense
                hide-details
                prepend-inner-icon="mdi-calendar-check"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="endDate"
              locale="ko"
              type="date"
              @change="saveEndDate"
            />
          </v-menu>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="keyword"
            dense
            hide-details
            label="검색어"
            append-icon="mdi-magnify"
            @click:append="getPagination(1)"
            @keyup="enterSearch"
          />
        </v-col>
      </v-row>
      <v-simple-table>
        <template #default>
          <tbody v-if="pagination && pagination.list.length === 0">
            <tr>
              <td colspan="6">
                <p class="text-center text-body-1">
                  포인트 변동 내역이 없습니다.
                </p>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="(item, i) in pagination.list" :key="i">
              <td>
                <span class="text-truncate">
                  {{ item.type }}
                </span>
              </td>
              <td>
                <span class="text-truncate">
                  {{ item.amount.toLocaleString() }}
                </span>
                <v-tooltip
                  v-if="item.lastAmount"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-chip
                      color="grey"
                      class="ml-2"
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      수정됨
                    </v-chip>
                  </template>
                  <span>
                    {{ item.lastAmount.toLocaleString() }}
                  </span>
                </v-tooltip>
              </td>
              <td>
                <span class="text-truncate">
                  {{ item.content ? item.content : '-' }}
                </span>
                <v-tooltip
                  v-if="item.lastContent"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-chip
                      color="grey"
                      class="ml-2"
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      수정됨
                    </v-chip>
                  </template>
                  <span>
                    {{ item.lastContent }}
                  </span>
                </v-tooltip>
              </td>
              <td>
                <span class="text-truncate">
                  {{ item.registerDate | moment('YYYY-MM-DD HH:mm:ss') }}
                </span>
                <v-tooltip
                  v-if="item.modifyDate"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-chip
                      color="grey"
                      class="ml-2"
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      수정됨
                    </v-chip>
                  </template>
                  <span>
                    {{ $parseDateSecond(item.modifyDate) }}
                  </span>
                </v-tooltip>
              </td>
              <td>
                {{ item.processAdmin ? item.processAdmin : '-' }}
              </td>
              <td>
                <v-dialog
                  v-model="item.showEditForm"
                  width="400"
                  persistent
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>
                        mdi-pen
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-subtitle-1">
                        포인트 기록 수정
                      </span>
                      <v-spacer />
                      <v-btn
                        icon
                        @click="closeEditForm(item)"
                      >
                        <v-icon>
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-select
                        v-model="item.historyType"
                        :items="historyTypes"
                        hide-details
                      />
                      <v-text-field
                        v-model="item.amount"
                        type="number"
                        hide-spin-buttons
                        hide-details
                      />
                      <v-text-field
                        v-model="item.content"
                        hide-details
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        width="100%"
                        @click="savePointHistory(item)"
                      >
                        수정
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-sheet v-if="pagination && pagination.list.length > 0" class="px-3 mt-3">
        <v-row>
          <v-col cols="12" md="8" offset-md="2">
            <LivePagination
              :pagination="pagination"
              :show-num="7"
              :query="$route.query"
              @pagination="getPagination"
              @last="getLastPage"
              @first="getFirstPage"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-card-text>
    <v-card-actions>
      <v-btn
        width="100%"
        @click="close"
      >
        닫기
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import LivePagination from '@/components/core/LivePagination'
  export default {
    name: 'ManagePointHistory',
    components: { LivePagination },
    props: [
      'user',
    ],
    data () {
      return {
        pagination: {
          list: [],
          pageNum: 1,
        },
        pageDataSize: 10,
        historyTypes: [
          {
            text: '활동',
            value: 'activity',
          },
          {
            text: '추천',
            value: 'recommend',
          },
          {
            text: '적립',
            value: 'fee',
          },
          {
            text: '출금',
            value: 'withdraw',
            disabled: true,
            disable: true,
          },
        ],
        pointType: '',
        pointTypes: [
          {
            text: '전체',
            value: '',
          },
          {
            text: '활동',
            value: 'activity',
          },
          {
            text: '적립',
            value: 'fee',
          },
          {
            text: '추천',
            value: 'recommend',
          },
          {
            text: '출금',
            value: 'withdraw',
          },
        ],
        sdt: '',
        edt: '',
        keyword: '',
        summary: null,
      }
    },
    computed: {
      thisUser () {
        return this.user
      },
      startDate: {
        get () {
          if (this.sdt) {
            const d = new Date()
            const year = parseInt(this.sdt.split('-')[0])
            const month = parseInt(this.sdt.split('-')[1])
            const day = parseInt(this.sdt.split('-')[2])
            d.setFullYear(year)
            d.setMonth(month - 1)
            d.setDate(day)
            d.setHours(0)
            d.setMinutes(0)
            d.setSeconds(0)
            d.setMilliseconds(0)
            return this.$moment(d).format('YYYY-MM-DD')
          } else {
            return null
          }
        },
        set (value) {
          this.sdt = value
        },
      },
      endDate: {
        get () {
          if (this.edt) {
            const d = new Date()
            const year = parseInt(this.edt.split('-')[0])
            const month = parseInt(this.edt.split('-')[1])
            const day = parseInt(this.edt.split('-')[2])
            d.setFullYear(year)
            d.setMonth(month - 1)
            d.setDate(day)
            d.setHours(0)
            d.setMinutes(0)
            d.setSeconds(0)
            d.setMilliseconds(0)
            return this.$moment(d).format('YYYY-MM-DD')
          } else {
            return null
          }
        },
        set (value) {
          this.edt = value
        },
      },
    },
    beforeMount () {
      this.getPagination()
      this.getProcessInfo()
    },
    methods: {
      saveStartDate (date) {
        this.sdt = date
      },
      saveEndDate (date) {
        this.edt = date
      },
      enterSearch () {
        if (event.keyCode === 13) {
          this.getPagination(1)
        }
      },
      getProcessInfo () {
        const url = `/manage/article/${this.thisUser.id}/summary`
        this.$axios.get(url).then(res => {
          if (res && res.data) {
            this.summary = res.data
          }
        })
      },
      getPagination (pageNum) {
        const url = `/manage/user/history/point/${this.thisUser.id}/${pageNum || this.pagination.pageNum}`
        const params = {
          pointType: this.pointType,
          sdt: this.sdt ? this.sdt : null,
          edt: this.edt ? this.edt : null,
          keyword: this.keyword ? this.keyword : null,
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
      },
      getLastPage () {
        const url = `/manage/user/history/point/${this.thisUser.id}/${this.pagination.maxPageNum}`
        const params = {
          pointType: this.pointType,
          sdt: this.sdt ? this.sdt : null,
          edt: this.edt ? this.edt : null,
          keyword: this.keyword ? this.keyword : null,
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
      },
      getFirstPage () {
        const url = `/manage/user/history/point/${this.thisUser.id}/1`
        const params = {
          pointType: this.pointType,
          sdt: this.sdt ? this.sdt : null,
          edt: this.edt ? this.edt : null,
          keyword: this.keyword ? this.keyword : null,
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
      },
      async savePointHistory (item) {
        await this.$axios.patch('/manage/user/history/point', item).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            this.closeEditForm(item)
          } else {
            alert(res.data.message || '오류가 발생하였습니다.')
          }
        }).catch(() => {
          alert('오류가 발생하였습니다.')
        })
      },
      close () {
        this.$emit('close-dialog')
      },
      async closeEditForm (item) {
        this.getPagination(this.pagination.pageNum)
        item.showEditForm = false
      },
    },
  }
</script>

<style scoped>

</style>
