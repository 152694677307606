<template>
  <div>
    <v-card>
      <v-card-title class="pt-10 pt-md-3">
        <v-icon class="mr-1">
          mdi-account-plus
        </v-icon>
        추가사용자 관리
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template #default>
            <tbody>
              <tr v-for="(item, i) in alias" :key="i">
                <td>
                  <v-btn
                    icon
                    @click="deleteAdditionalUser(item)"
                  >
                    <v-icon
                      :color="item.visible ? 'white' : 'red'"
                    >
                      mdi-trash-can
                    </v-icon>
                  </v-btn>
                </td>
                <td
                  class="text-truncate"
                  :class="{
                    'text-decoration-line-through red--text': !item.visible,
                  }"
                >
                  {{ item.id }}
                </td>
                <td
                  class="text-truncate"
                  :class="{
                    'text-decoration-line-through red--text': !item.visible,
                  }"
                >
                  {{ item.username }}
                </td>
                <td
                  class="text-truncate"
                  :class="{
                    'text-decoration-line-through red--text': !item.visible,
                  }"
                >
                  {{ item.mobile }}
                </td>
                <td
                  class="text-truncate"
                  :class="{
                    'text-decoration-line-through red--text': !item.visible,
                  }"
                >
                  {{ item.userAddress ? item.userAddress + ' ' + (alias.userAddressDetail || '') : '주소 정보 없음' }}
                </td>
              </tr>
              <tr>
                <td colspan="5">
                  <v-dialog
                    v-model="isShowAdd"
                    width="400"
                    persistent
                    :fullscreen="mobile"
                    :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
                  >
                    <template #activator="{ attrs, on }">
                      <v-btn
                        text
                        width="100%"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon class="mr-1">
                          mdi-plus
                        </v-icon>
                        사용자 추가
                      </v-btn>
                    </template>
                    <v-card class="d-flex align-start flex-wrap">
                      <v-card-title class="mb-auto pt-10 pt-md-3" :style="{ width: '100%' }">
                        <v-icon class="mr-1">
                          mdi-plus
                        </v-icon>
                        사용자 추가
                      </v-card-title>
                      <v-card-text class="mb-auto d-flex align-start">
                        <v-row>
                          <v-col cols="12">
                            <v-text-field v-model="newUser.id" label="ID" />
                            <v-text-field v-model="newUser.name" label="Name" />
                            <v-text-field v-model="newUser.mobile" label="Mobile" />
                            <v-text-field v-model="newUser.address" label="Address" @focus="addressSearch" />
                            <v-text-field v-model="newUser.userAddressDetail" label="Address Detail" />
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="flex-wrap mt-auto" :style="{ width: '100%' }">
                        <v-btn
                          width="100%"
                          large
                          class="mx-0 mb-2"
                          @click="registerAdditionalUser"
                        >
                          완료
                        </v-btn>
                        <v-btn
                          width="100%"
                          large
                          class="mx-0"
                          @click="closeAdd"
                        >
                          창 닫기
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                    <DaumAddress ref="address" :show-address="showAddress" :style="{ zIndex: 1000 }" @close="showAddress = false" @update="updateAddress" />
                  </v-dialog>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions class="flex-wrap">
        <v-btn
          width="100%"
          large
          class="mx-0"
          @click="close"
        >
          창 닫기
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import DaumAddress from '../../../components/core/DaumAddress'
  export default {
    name: 'ManageAdditionalUser',
    components: { DaumAddress },
    props: [
      'user',
    ],
    data () {
      return {
        showAddress: false,
        isShowAdd: false,
        newUser: {
          id: '',
          name: '',
          mobile: '',
          address: '',
          userAddressDetail: '',
        },
        alias: [],
      }
    },
    computed: {
      thisUser () {
        return this.user
      },
      mobile () {
        return this.$isMobile
      },
    },
    beforeMount () {
      this.getAliasUsers()
    },
    methods: {
      getAliasUsers () {
        const url = `/manage/user/alias/${this.thisUser.id}`
        this.$axios.get(url)
          .then(res => {
            if (res && res.data) {
              this.alias = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      async registerAdditionalUser () {
        const url = `/manage/user/alias/${this.thisUser.idx}`
        const newUser = {
          id: this.newUser.id,
          mobile: this.newUser.mobile,
          userAddress: this.newUser.address,
          userAddressDetail: this.newUser.userAddressDetail,
          username: this.newUser.name,
        }
        const mobilePattern = /^[0-9]+$/
        if (!mobilePattern.test(this.newUser.mobile)) {
          alert('휴대폰 번호는 숫자만 입력해주세요.')
          return
        }
        await this.$axios.post(url, newUser).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            alert('추가 사용자를 등록하였습니다.')
            this.newUser = {
              id: null,
              address: null,
              name: null,
              userAddressDetail: null,
            }
            this.closeAdd()
            this.getAliasUsers()
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(e => {
          alert('오류가 발생하였습니다.')
        })
      },
      async deleteAdditionalUser (alias) {
        let message = '추가 사용자를 삭제하시겠습니까?'
        if (!alias.visible) {
          message = '추가 사용자를 완전 삭제하시겠습니까?\n사용자의 게시글, 포인트 적립 내역 및 모든 정보가 삭제됩니다.'
        }
        if (confirm(message)) {
          const url = `/manage/user/alias/${alias.idx}`
          await this.$axios.delete(url, alias).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              alert('추가 사용자를 삭제했습니다.')
              this.getAliasUsers()
            } else {
              alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
            }
          }).catch(e => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
      close () {
        this.$emit('close-dialog')
      },
      closeAdd () {
        this.isShowAdd = false
      },
      addressSearch () {
        this.$refs.address.addressSearch()
        this.showAddress = true
      },
      closeAddressSearch () {
        this.showAddress = false
      },
      updateAddress (address) {
        this.newUser.address = address
      },
    },
  }
</script>

<style scoped>

</style>
