<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <ManageUserHeader />
      <ManageUserSearch @search="getPagination" />
      <ManageUserContent ref="manageUserContent" :pagination="pagination" :payload="payload" @refresh="getPagination" />
      <v-sheet class="px-3">
        <v-row>
          <v-col cols="12" md="3">
            <v-btn
              class="mr-1"
              large
              @click="updateUsersStatus('confirm')"
            >
              승인
            </v-btn>
            <v-btn
              class="mr-1"
              large
              @click="updateUsersStatus('reject')"
            >
              탈퇴
            </v-btn>
            <v-dialog
              v-model="pointHistory.showPointEdit"
              width="400px"
              persistent
              :fullscreen="mobile"
              :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
            >
              <template #activator="{ attrs, on }">
                <v-btn
                  large
                  v-bind="attrs"
                  v-on="on"
                >
                  포인트
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="pt-10 pt-md-3">
                  <v-icon class="mr-1">
                    mdi-alpha-p-box
                  </v-icon>
                  포인트 처리
                </v-card-title>
                <v-card-text>
                  <v-radio-group v-model="pointHistory.pointHistoryType" class="d-flex" row>
                    <v-radio
                      label="활동"
                      value="activity"
                    />
                    <v-radio
                      label="적립"
                      value="fee"
                    />
                  </v-radio-group>
                  <v-text-field
                    v-model="pointHistory.amount"
                    label="적립할 포인트"
                    type="number"
                    hide-spin-buttons
                  />
                  <v-text-field
                    v-model="pointHistory.content"
                    label="적립/차감 내용을 입력해주세요"
                  />
                </v-card-text>
                <v-card-actions class="flex-wrap">
                  <v-btn
                    width="100%"
                    large
                    class="mx-0 mb-2"
                    @click="updateUsersPoint"
                  >
                    포인트 처리
                  </v-btn>
                  <v-btn
                    width="100%"
                    large
                    class="mx-0"
                    @click="closePointEdit"
                  >
                    창닫기
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="12" md="6">
            <Pagination ref="pagination" :pagination="pagination" :show-num="7" :base-url="'/manage/users/'" :query="$route.query" />
          </v-col>
          <v-col cols="12" md="3" class="text-right">
            <v-btn
              @click="remappingAlias"
            >
              추가사용자 동기화
            </v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
  </v-lazy>
</template>

<script>
  import ManageUserHeader from '@/views/manage/user/Header'
  import ManageUserContent from '@/views/manage/user/Content'
  import ManageUserSearch from '@/views/manage/user/Search'
  import Pagination from '@/components/core/Pagination'
  export default {
    name: 'ManageUser',
    components: { Pagination, ManageUserSearch, ManageUserContent, ManageUserHeader },
    data () {
      return {
        pointHistory: {
          amount: null,
          content: null,
          pointHistoryType: null,
          pointHistoryTypes: [
            {
              text: '활동',
              value: 'activity',
            },
            {
              text: '적립',
              value: 'fee',
            },
          ],
          showPointEdit: false,
        },
        pagination: {
          list: [],
          pageNum: 1,
        },
        pageDataSize: '10',
        payload: null,
      }
    },
    computed: {
      pageNum () {
        return this.$route.params.pageNum
      },
      status () {
        return this.$route.query.status
      },
      field () {
        return this.$route.query.field
      },
      keyword () {
        return this.$route.query.keyword
      },
      mobile () {
        return this.$isMobile
      },
      selected () {
        return this.$refs.manageUserContent.selected
      },
    },
    watch: {
      pageNum () {
        this.getPagination()
      },
    },
    beforeMount () {
      this.getPagination()
    },
    methods: {
      remappingAlias () {
        const url = '/manage/user/remapping'
        this.$axios.post(url).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            alert('동기화를 완료하였습니다.')
          } else {
            alert(res.data.message || '오류가 발생하였습니다.')
          }
        }).catch(() => {
          alert('오류가 발생하였습니다.')
        })
      },
      getPagination (payload) {
        const url = `/manage/user/users/${payload && payload.pageNum ? payload.pageNum : this.pageNum}`
        this.payload = payload
        const data = {
          pageDataSize: this.pageDataSize,
          keyword: payload && payload.keyword ? payload.keyword : this.keyword,
          status: payload && payload.status ? payload.status : this.status,
          field: payload && payload.field ? payload.field : this.field,
        }
        this.$axios.get(url, {
          params: data,
        })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      async updateUsersStatus (status) {
        const url = '/manage/user/status'
        const data = {
          idxes: this.selected,
          status: status,
        }
        await this.$axios.patch(url, data).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            alert(res.data.message)
            this.getPagination()
            this.$refs.manageUserContent.clearSelected()
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(e => {
          alert('오류가 발생하였습니다.')
        })
      },
      async updateUsersPoint () {
        const url = '/manage/user/points'
        const data = {
          pointHistory: {
            amount: this.pointHistory.amount,
            historyType: this.pointHistory.pointHistoryType,
            content: this.pointHistory.content,
          },
          idxes: this.selected,
        }
        await this.$axios.patch(url, data).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            let msg = res.data.message
            msg = msg.replaceAll('|', '\r\n')
            alert(msg)
            this.getPagination()
            this.$refs.manageUserContent.clearSelected()
            this.closePointEdit()
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(e => {
          alert('오류가 발생하였습니다.')
        })
      },
      closePointEdit () {
        this.pointHistory.content = null
        this.pointHistory.showPointEdit = false
        this.pointHistory.pointHistoryType = null
        this.pointHistory.amount = null
      },
    },
  }
</script>

<style scoped>

</style>
