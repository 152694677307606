<template>
  <v-row class="mt-10">
    <v-col cols="3">
      <v-select
        v-model="query.status"
        label="Status"
        :items="items.status"
      />
    </v-col>
    <v-col cols="3">
      <v-select
        v-model="query.field"
        label="Fields"
        :items="items.field"
      />
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="query.keyword"
        label="Keyword"
        placeholder="검색어를 입력해주세요."
        append-icon="mdi-magnify"
        @keydown="enterSearch"
        @click:append="search"
      />
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ManageUserSearch',
    data () {
      return {
        query: {
          status: '',
          field: '',
          keyword: '',
        },
        items: {
          status: [
            {
              text: '전체',
              value: '',
            },
            {
              text: '대기',
              value: 'pending',
            },
            {
              text: '사용중',
              value: 'confirm',
            },
            {
              text: '탈퇴',
              value: 'reject',
            },
          ],
          field: [
            {
              text: '전체',
              value: '',
            },
            {
              text: 'ID',
              value: 'id',
            },
            {
              text: '이름',
              value: 'username',
            },
            {
              text: '이메일',
              value: 'email',
            },
            {
              text: '주소',
              value: 'userAddress',
            },
            {
              text: '계좌',
              value: 'userAccount',
            },
            {
              text: '전화번호',
              value: 'mobile',
            },
            {
              text: '추천인',
              value: 'recommender',
            },
            {
              text: '추가 사용자',
              value: 'alias',
            },
          ],
        },
      }
    },
    methods: {
      search () {
        const data = {
          status: this.query.status,
          field: this.query.field,
          keyword: this.query.keyword,
        }
        this.$router.push({
          path: '/manage/users/1',
          query: data,
        }, () => {
          this.$emit('search', data)
        })
      },
      enterSearch () {
        if (event.keyCode === 13) {
          this.search()
        }
      },
    },
  }
</script>

<style scoped>

</style>
