<template>
  <v-row>
    <v-col cols="12">
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th>
                <v-checkbox v-model="checkAll" />
              </th>
              <th />
              <th
                v-for="(col, i) in cols"
                :key="i"
                class="text-subtitle-1 text-center"
              >
                {{ col }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="user in pagination.list">
              <tr :key="user.idx">
                <td class="text-center">
                  <v-checkbox v-model="selected" :value="user.idx" />
                </td>
                <td class="text-center">
                  <v-menu
                    v-model="user.showMenu"
                  >
                    <template #activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-account-edit
                      </v-icon>
                    </template>
                    <v-list>
                      <v-dialog
                        v-model="user.showEdit"
                        persistent
                        width="400px"
                        :fullscreen="mobile"
                        :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
                      >
                        <template #activator="{ attrs, on }">
                          <v-list-item
                            v-bind="attrs"
                            v-on="on"
                          >
                            정보수정
                          </v-list-item>
                        </template>
                        <ManageUserEdit :user="user" @close-dialog="closeDialog(user)" />
                      </v-dialog>
                      <v-dialog
                        v-model="user.showAdditional"
                        persistent
                        width="800px"
                        :fullscreen="mobile"
                        :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
                      >
                        <template #activator="{ attrs, on }">
                          <v-list-item
                            v-bind="attrs"
                            v-on="on"
                          >
                            추가사용자 관리
                          </v-list-item>
                        </template>
                        <ManageAdditionalUser :user="user" @close-dialog="closeDialog(user)" />
                      </v-dialog>
                      <v-dialog
                        v-model="user.showPointHistory"
                        persistent
                        width="1200px"
                        :fullscreen="mobile"
                        :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
                      >
                        <template #activator="{ attrs, on }">
                          <v-list-item
                            v-bind="attrs"
                            v-on="on"
                          >
                            포인트 정보
                          </v-list-item>
                        </template>
                        <ManagePointHistory :user="user" @close-dialog="closeDialog(user)" />
                      </v-dialog>
                    </v-list>
                  </v-menu>
                </td>
                <td class="text-center">
                  <template v-if="user.userAuth === 'U'">
                    <v-tooltip bottom>
                      <template #activator="{ attrs, on }">
                        <v-icon
                          v-if="user.status === 'pending'"
                          color="yellow"
                          class="d-inline-block"
                          v-bind="attrs"
                          v-on="on"
                          @click="showAlias = !showAlias"
                        >
                          mdi-clock-minus
                        </v-icon>
                      </template>
                      <span>
                        추가 사용자 {{ showAlias ? '접기' : '펼치기' }}
                      </span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template #activator="{ attrs, on }">
                        <v-icon
                          v-if="user.status === 'reject'"
                          color="red"
                          class="d-inline-block"
                          v-bind="attrs"
                          v-on="on"
                          @click="showAlias = !showAlias"
                        >
                          mdi-cancel
                        </v-icon>
                      </template>
                      <span>
                        추가 사용자 {{ showAlias ? '접기' : '펼치기' }}
                      </span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template #activator="{ attrs, on }">
                        <v-icon
                          v-if="user.status === 'confirm'"
                          color="success"
                          class="d-inline-block"
                          v-bind="attrs"
                          v-on="on"
                          @click="showAlias = !showAlias"
                        >
                          mdi-account-check
                        </v-icon>
                      </template>
                      <span>
                        추가 사용자 {{ showAlias ? '접기' : '펼치기' }}
                      </span>
                    </v-tooltip>
                  </template>
                  <template
                    v-else
                  >
                    <v-tooltip bottom>
                      <template #activator="{ attrs, on }">
                        <v-icon
                          color="red"
                          v-bind="attrs"
                          v-on="on"
                          @click="showAlias = !showAlias"
                        >
                          mdi-account-cowboy-hat
                        </v-icon>
                      </template>
                      <span>
                        추가 사용자 {{ showAlias ? '접기' : '펼치기' }}
                      </span>
                    </v-tooltip>
                  </template>
                </td>
                <td class="text-center" @click="loginToUser(user.id, user.idx)">
                  <v-icon v-if="user.ban" size="14" class="font-weight-bold" style="color: red">
                    mdi-cancel
                  </v-icon>
                  <span
                    class="text-truncate"
                  >
                    {{ user.id }}
                  </span>
                </td>
                <td class="text-center">
                  <span class="text-truncate">
                    {{ user.username }}
                  </span>
                </td>
                <td class="text-center">
                  <span class="text-truncate">
                    {{ user.userAccount }}
                  </span>
                </td>
                <td class="text-center">
                  <span class="text-truncate">
                    {{ user.mobile }}
                  </span>
                </td>
                <td class="text-center">
                  <span class="text-truncate">
                    {{ user.userAddress }} {{ user.userAddressDetail }}
                  </span>
                </td>
                <td class="text-center">
                  <span class="text-truncate">
                    {{ user.recommender }}
                  </span>
                </td>
                <td class="text-center">
                  <span class="text-truncate">
                    {{ user.joinDate | moment('YYYY.MM.DD HH:mm:ss') }}
                  </span>
                </td>
                <td class="text-center">
                  <span class="text-truncate">
                    <template v-if="user.recentDate">
                      <template v-if="new Date().getTime() - user.recentDate < 1000 * 60 * 60 * 7">
                        {{ user.recentDate | moment('from', 'now') }}
                      </template>
                      <template v-else>
                        {{ user.recentDate | moment('YYYY.MM.DD. HH:mm:ss') }}
                      </template>
                    </template>
                  </span>
                </td>
                <td class="text-center">
                  <span class="text-truncate">
                    -
                  </span>
                </td>
              </tr>
              <template v-if="user.aliases && user.aliases.length > 0 && showAlias">
                <tr v-for="alias of user.aliases" :key="'alias-' + alias.idx">
                  <td class="text-center">
                    <v-icon>
                      mdi-subdirectory-arrow-right
                    </v-icon>
                  </td>
                  <td class="text-center" />
                  <td class="text-center">
                    <v-icon>
                      mdi-link
                    </v-icon>
                  </td>
                  <td class="text-center">
                    <span
                      class="text-truncate"
                    >
                      {{ alias.id }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-truncate">
                      {{ alias.username }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-truncate">
                      {{ user.userAccount }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-truncate">
                      {{ alias.mobile }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-truncate">
                      {{ alias.userAddress }} {{ alias.userAddressDetail }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-truncate">
                      -
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-truncate">
                      {{ alias.joinDate | moment('YYYY.MM.DD HH:mm:ss') }}
                    </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    <span class="text-truncate">
                      {{ user.id }}
                    </span>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
  import ManageUserEdit from '@/views/manage/user/Edit'
  import ManageAdditionalUser from '@/views/manage/user/AdditionalUser'
  import ManagePointHistory from '@/views/manage/user/PointHistory'
  export default {
    name: 'ManageUserContent',
    components: { ManagePointHistory, ManageAdditionalUser, ManageUserEdit },
    props: {
      pagination: {},
      payload: {},
    },
    data () {
      return {
        cols: ['Status', 'ID', 'Name', 'Account', 'Mobile', 'Address', 'Rcm', 'Join', 'Latest', 'Alias'],
        search: {
          pageDataSize: 15,
          status: '',
          keyword: '',
          field: '',
        },
        loginUrl: '',
        selected: [],
        showMenu: false,
        showAlias: false,
      }
    },
    computed: {
      checkAll: {
        get () {
          return this.pagination ? (this.selected ? (this.selected.length === this.pagination.list.length) : false) : false
        },
        set (value) {
          const selected = []
          if (value) {
            this.pagination.list.forEach(user => {
              selected.push(user.idx)
            })
            this.selected = selected
          } else {
            this.selected = []
          }
        },
      },
      mobile () {
        return this.$isMobile
      },
    },
    methods: {
      async loginToUser (id, usersIdx) {
        const url = `/manage/user/user/password?usersIdx=${usersIdx}`
        await this.$axios.get(url).then(res => {
          if (res.data) {
            const loginUrl = 'http://saycompany.co.kr/login?username=' + id + '&password=' + encodeURIComponent(res.data) + '&isAdmin=true'
            // const loginUrl = 'http://localhost:8080/login?username=' + id + '&password=' + encodeURIComponent(res.data) + '&isAdmin=true'
            this.$copyText(loginUrl)
            alert('로그인 URL 주소가 복사되었습니다. 개인정보보호 모드에서 붙여넣기 후 사용해주세요.')
          }
        })
      },
      closeDialog (user) {
        user.showMenu = false
        user.showEdit = false
        user.showAdditional = false
        this.$emit('refresh', this.payload)
      },
      clearSelected () {
        this.selected = []
      },
    },
  }
</script>

<style scoped>

</style>
