<template>
  <div>
    <v-card v-if="user">
      <v-card-title class="pt-10 pt-md-3 flex">
        <div>
          <v-icon class="mr-1">
            mdi-account-edit
          </v-icon>
          사용자 정보수정
        </div>
        <v-spacer />
        <div style="position: relative; top: -5px;">
          <v-checkbox v-model="thisUser.excludeSync" hide-details dense label="동기화 예외" />
        </div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="thisUser.id" disabled label="ID" />
            <v-text-field v-model="thisUser.username" label="Username" />
            <v-text-field v-if="thisUser.userAuth !== 'M'" v-model="thisUser.newPassword" label="Password" type="password" />
            <v-text-field v-model="thisUser.email" label="Email" />
            <v-text-field v-model="thisUser.mobile" label="Mobile" />
            <v-text-field v-model="thisUser.userAddress" label="Address" @focus="addressSearch" />
            <v-text-field v-model="thisUser.userAddressDetail" label="Address Detail" />
            <v-text-field v-model="thisUser.userAccount" label="Account Info" />
            <v-text-field v-model="thisUser.recommender" label="Recommend" />
            <v-btn color="green" class="mr-1" small @click="enableAllAssigns">
              <span class="white--text">
                모든 배정 가능처리
              </span>
            </v-btn>
            <v-btn color="red" small @click="disableAllAssigns">
              <span class="white--text">
                모든 배정 불가처리
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="flex-wrap">
        <v-btn
          width="100%"
          large
          class="mb-2"
          @click="saveUserInfo"
        >
          정보저장
        </v-btn>
        <v-btn
          width="100%"
          large
          class="mx-0"
          @click="close"
        >
          창 닫기
        </v-btn>
      </v-card-actions>
    </v-card>
    <DaumAddress ref="address" :show-address="showAddress" @close="showAddress = false" @update="updateAddress" />
  </div>
</template>

<script>
  import DaumAddress from '../../../components/core/DaumAddress'
  export default {
    name: 'ManageUserEdit',
    components: { DaumAddress },
    props: [
      'user',
    ],
    data () {
      return {
        showAddress: false,
      }
    },
    computed: {
      thisUser () {
        return this.user
      },
    },
    methods: {
      async saveUserInfo () {
        const url = '/manage/user/info'
        await this.$axios.patch(url, this.thisUser).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            alert('처리가 완료되었습니다.')
            this.$emit('refresh')
            this.close()
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(e => {
          alert('오류가 발생하였습니다.')
        })
      },
      async disableAllAssigns () {
        if (confirm('해당 사용자의 모든 배정을 \'불가\' 처리 합니다.\n진행하시겠습니까?')) {
          const url = '/manage/user/assign/disable'
          await this.$axios.patch(url, this.thisUser).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              alert('처리가 완료되었습니다.')
              this.$emit('refresh')
              this.close()
            } else {
              alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
            }
          }).catch(e => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
      async enableAllAssigns () {
        if (confirm('해당 사용자의 모든 배정을 \'가능\' 처리 합니다.\n진행하시겠습니까?')) {
          const url = '/manage/user/assign/enable'
          await this.$axios.patch(url, this.thisUser).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              alert('처리가 완료되었습니다.')
              this.$emit('refresh')
              this.close()
            } else {
              alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
            }
          }).catch(e => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
      close () {
        this.$emit('close-dialog')
      },
      addressSearch () {
        this.$refs.address.addressSearch()
        this.showAddress = true
      },
      closeAddressSearch () {
        this.showAddress = false
      },
      updateAddress (address) {
        this.thisUser.userAddress = address
      },
    },
  }
</script>

<style scoped lang="scss">
</style>
